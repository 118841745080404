<template>
	<div>
		<div class="h-auto filter mb-5">
			<div class="w-full rounded-lg flex flex-wrap items-center justify-between gap-5">
				<h1 class="text-xl h-6 font-extrabold sm:text-2xl">Конструктор анкет</h1>
				<div class="flex items-center justify-end gap-5">
					<Button v-if="userIsInRoles(['Support', 'MZ_Employee'])" text="Добавить" icon="add" @click="addNewForm" />
					<Button text="Экспорт в Excel" icon="exel" @click.native="downloadExcell(params)" :disabled="loadToExcell" :load="loadToExcell" />
				</div>
			</div>
		</div>
		<div class="flex flex-col relative">
			<div class="t-overflow rounded-t-lg overflow-x-auto h-auto bg-white filter shadow-default" :style="data.length > 9 ? 'height: 710px' : ''">
				<table class="w-full" :style="data.length > 0 ? 'min-height: 300px' : ''">
					<thead>
						<tr :class="{ tr1__sticky: params.pageSize > 5 }">
							<th class="text-center border-b border-r border-gray-200 text-sm text-left leading-4 font-semibold text-gray-500 tracking-wider">П/П</th>
							<th
								v-for="(item, ind) in fields"
								:key="'J' + ind"
								class="px-6 py-4 border-b border-r border-gray-200 text-sm text-left leading-4 font-semibold text-gray-500 tracking-wider"
							>
								<span class="flex relative">
									<span class="border-b border-gray-200 cursor-pointer" :class="{ 'text-blue-500 underline': filterBy === ind }" @click="switchFilterVisibility(ind)">
										{{ item.Name }}
									</span>
									<Icons v-if="params.filters.find((f) => f.fieldName === item.fieldName)" icon-name="filter" class="hidden sm:block ml-2" />
									<FilterComponent
										v-on:change="switchFilterVisibility(null)"
										:filterOpen="filterOpen"
										:position="ind > 2 ? 'right' : 'left'"
										:id-filter="ind"
										:param="params"
										@fromPage="onePage"
										@query="getData"
										:fields="fields"
										:item="item"
									/>
								</span>
							</th>
							<th class="px-6 py-4 border-b border-r border-gray-200 text-left text-sm leading-4 font-semibold text-gray-500 tracking-wider">
								<span class="relative">
									<span> Действие </span>
								</span>
							</th>
						</tr>
					</thead>
					<tbody>
						<tr v-for="(item, indx) in data" :key="'A' + indx">
							<td class="px-6 font-normal text-sm whitespace-no-wrap border-b border-r text-left border-gray-200 leading-5 text-gray-500">
								<span v-if="params.currentPage === 0">
									{{ indx + 1 }}
								</span>
								<span v-if="params.currentPage > 0">
									{{ params.currentPage * params.pageSize + (indx + 1) }}
								</span>
							</td>
							<td class="px-6 py-4 font-normal text-sm whitespace-no-wrap border-b border-r text-left border-gray-200 leading-5 text-gray-500">
								<span>
									{{ item.typeName }}
								</span>
							</td>
							<td class="px-6 py-4 font-normal text-sm whitespace-no-wrap border-b border-r text-left border-gray-200 leading-5 text-gray-500">
								<span>
									{{ item.name }}
								</span>
							</td>
							<td class="px-6 py-4 font-normal text-sm text-center whitespace-no-wrap border-b border-r border-gray-200 leading-5">
								<span class="text-center text-xs px-2 py-1 rounded bg-lightOrange text-orange" v-if="item.status === 1"> Черновик </span>
								<span class="text-center text-xs px-2 py-1 rounded bg-green-50 text-green-500" v-if="item.status === 2"> Опубликовано </span>
								<span class="text-center text-xs px-2 py-1 rounded bg-red-50 text-red-500" v-if="item.status === 3"> Архив </span>
							</td>
							<td class="px-6 py-4 font-normal text-sm whitespace-no-wrap border-b border-r text-left border-gray-200 leading-5">
								<span v-if="item.isPublic && item.status === 2">
									<a class="underline text-blue-500" :href="`https://anketanok.minzdrav.gov.ru/anketa/${item.id}`" target="_blank" rel="noopener noreferrer">
										{{ `https://anketanok.minzdrav.gov.ru/anketa/${item.id}` }}
									</a>
								</span>
								<span v-if="item.isPublic === false && item.status === 2">
									<ul>
										<li v-for="(it, ind) in item.places" :key="'F' + ind">
											{{ it.name }}
										</li>
									</ul>
								</span>
							</td>
							<td class="px-6 py-4 font-normal text-sm text-left whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-gray-500">
								<span v-if="item.status === 1" class="flex">
									<Icons
										v-if="userIsInRoles(['Support', 'MZ_Employee'])"
										title="Копировать анкету"
										@click.native="copyAnketa(item, indx)"
										class="mr-2 icon-control"
										icon-name="copy-grey"
									/>
									<Icons
										v-if="userIsInRoles(['Support', 'MZ_Employee'])"
										title="Удалить анкету"
										@click.native="deleteAnket(item, indx)"
										class="mr-2 icon-control"
										icon-name="trash"
									/>
									<router-link :to="{ name: 'editeForm', params: { anketId: item.id }, query: { edite: true } }">
										<Icons class="mr-2 icon-control" icon-name="pen" title="Редактировать анкету" />
									</router-link>
									<Icons
										v-if="userIsInRoles(['Support', 'MZ_Employee'])"
										title="Опубликовать анкету"
										@click.native="publicAnket(item, indx)"
										class="icon-control"
										icon-name="publish-grey"
									/>
								</span>
								<span v-if="item.status === 2" class="flex">
									<router-link :to="{ name: 'viewForm', params: { anketId: item.id }, query: { view: true } }">
										<Icons class="mr-2 icon-control" icon-name="view-grey" title="Посмотреть анкету" />
									</router-link>
									<Icons
										v-if="userIsInRoles(['Support', 'MZ_Employee'])"
										title="Архивировать анкету"
										@click.native="archiveAnketa(item, indx)"
										class="mr-2 icon-control"
										icon-name="archive-grey"
									/>
									<Icons
										v-if="userIsInRoles(['Support', 'MZ_Employee'])"
										title="Копировать анкету"
										@click.native="copyAnketa(item, indx)"
										class="icon-control"
										icon-name="copy-grey"
									/>
								</span>
								<span v-if="item.status === 3" class="flex">
									<router-link :to="{ name: 'viewForm', params: { anketId: item.id }, query: { view: true } }">
										<Icons class="mr-2 icon-control" icon-name="view-grey" title="Посмотреть анкету" />
									</router-link>
									<Icons
										v-if="userIsInRoles(['Support', 'MZ_Employee'])"
										title="Копировать анкету"
										@click.native="copyAnketa(item, indx)"
										class="icon-control"
										icon-name="copy-grey"
									/>
								</span>
							</td>
						</tr>
					</tbody>
				</table>
				<center v-if="!loadingTable && data.length === 0" class="w-full py-28 whitespace-no-wrap text-xl leading-5 text-gray-500 text-center">Данных нет...</center>
			</div>
			<!-- Навигация -->
			<div class="h-auto rounded-b-lg bg-white filter">
				<Pagination :rowsCount="totalRows" :pageSize="params.pageSize" :pageNumber="params.currentPage" :pagesCount="totalCount" @query="updatePage" />
			</div>
			<!-- Прелоудер -->
			<div v-if="loadingTable" class="absolute w-full h-full preloader rounded-lg">
				<loadingComponent class="absolute top-1/2 left-1/2" />
			</div>
		</div>
	</div>
</template>

<script>
import { getters, methods } from '@/store/store';
import FilterComponent from '@/components/Filter.vue';
import Icons from '@/components/Icons.vue';
import Pagination from '@/components/Pagination.vue';
import loadingComponent from '@/components/Loading.vue';
import Button from '@/components/Button.vue';
import { getAnkets, setCreateAnket, deleteAnketa, copyAnketaFormId, updateAnketaStatus, inlineSaveForUrl } from '../store/actions';

export default {
	name: 'TableForms',
	components: {
		loadingComponent,
		Icons,
		FilterComponent,
		Pagination,
		Button
	},
	computed: {
		...getters
	},
	data() {
		return {
			loadToExcell: false,
			loadingTable: false,
			filterOpen: null,
			filterBy: null,
			totalRows: 0,
			totalCount: 0,
			key: 0,
			page: 0,
			params: {
				filters: [],
				sortOption: {
					fieldName: '',
					sortType: null
				},
				useANDoperator: true,
				columns: [],
				pageSize: this.$DefaultPageSize,
				currentPage: 0
			},
			fields: [
				{
					Name: 'Тип',
					fieldName: 'type',
					filterType: 2,
					filterValueType: 1,
					value1: '',
					value2: null,
					sortBy: null,
					label1: 'По возрастанию (А-Я)',
					label2: 'По убыванию (Я-А)'
				},
				{
					Name: 'Наименование',
					fieldName: 'name',
					filterType: 2,
					filterValueType: 1,
					value1: '',
					value2: null,
					sortBy: null,
					label1: 'По возрастанию',
					label2: 'По убыванию'
				},
				{
					Name: 'Статус',
					fieldName: 'status',
					filterType: 2,
					filterValueType: 1,
					value1: '',
					value2: null,
					sortBy: null,
					label1: 'По возрастанию',
					label2: 'По убыванию'
				},
				{
					Name: 'Ссылка',
					fieldName: 'link',
					filterType: 1,
					filterValueType: 1,
					value1: '',
					value2: null,
					sortBy: null,
					label1: 'По возрастанию',
					label2: 'По убыванию'
				}
			],
			data: [],
			setMapRouter: {
				'213c7ff8-6b63-449d-9e4e-adc3fc9e5fe0': 'minzdrav/general',
				'213c7ff8-6b63-449d-9e4e-adc3fc9e5fe9': 'minzdrav/guide',
				'213c7ff8-6b63-449d-9e4e-adc3fc9e5fe8': 'minzdrav/patient-reviews',
				'213c7ff8-6b63-449d-9e4e-adc3fc9e5fe7': 'ogv/medical-organizations',
				'213c7ff8-6b63-449d-9e4e-adc3fc9e5fe6': 'ogv/public-council-noc',
				'213c7ff8-6b63-449d-9e4e-adc3fc9e5fe5': 'ogv/archive',
				'213c7ff8-6b63-449d-9e4e-adc3fc9e5fe4': 'minzdrav/noc-results-archive',
				'213c7ff8-6b63-449d-9e4e-adc3fc9e5fe3': 'ogv/noc-current-year/noc-results-current-years',
				'213c7ff8-6b63-449d-9e4e-adc3fc9e5fe2': 'minzdrav/noc-results-current-years',
				'213c7ff8-6b63-449d-9e4e-adc3fc9e5fea': 'minzdrav/report-constructor'
			}
		};
	},
	methods: {
		userIsInRoles: methods.userIsInRoles,
		updatePage(input) {
			this.params.pageSize = input.pageSize;
			this.params.currentPage = input.pageNumber;
			this.getData(this.params, false);
		},
		getData(params, flag) {
			this.loadingTable = true;
			// если фильтруем/сортируем то возвращаем пагинацию на 1 страницу
			if (flag) {
				this.params.currentPage = 0;
				this.page = 12;
			}

			getAnkets(this.$http, params).then((res) => {
				this.data = res.data.data;
				this.totalRows = res.data.totalRecords;
				this.totalCount = params.pageSize ? Math.ceil(res.data.totalRecords / params.pageSize) : 1;
				this.loadingTable = false;
			});
		},
		onePage(page) {
			this.key += 1;
			this.page = page;
			this.params.currentPage = page;
		},
		switchFilterVisibility(key) {
			this.filterData = {
				sortBy: 'asc',
				searchValue: ''
			};
			this.filterOpen = this.filterOpen === key ? null : key;
			this.filterBy = key;
		},
		addNewForm() {
			setCreateAnket(this.$http, {
				type: 1,
				status: 1,
				name: '',
				isApproved: true,
				comment: '',
				isDeleted: true,
				questions: []
			}).then((res) => {
				this.$router.push({
					name: 'addForm',
					params: {
						anketId: res.data.id
					}
				});
			});
		},
		editeAnket(row) {
			this.$router.push({
				name: 'editeForm',
				params: {
					anketId: row.id
				},
				query: {
					edite: true
				}
			});
		},
		deleteAnket(row, indx) {
			if (confirm('Вы уверены что хотите удалить анкету ?')) {
				deleteAnketa(this.$http, row.id).then(() => {
					this.showNotification(`Анкета ${row.name} успешно удалена!`, 'success');
					this.getData(this.params);
					this.data.splice(indx, 1);
				});
			}
		},
		copyAnketa(row, indx) {
			if (confirm('Вы уверены что хотите создать копию анкеты ?')) {
				copyAnketaFormId(this.$http, row.id).then((res) => {
					this.showNotification(`Копия анкеты ${row.name} создана!`, 'success');
					this.data.splice(indx + 1, 0, res.data);
				});
			}
		},
		archiveAnketa(row, indx) {
			if (confirm('Вы уверены что хотите заархивировать анкету ?')) {
				updateAnketaStatus(this.$http, row.id, 3).then(() => {
					for (let i = 0; i < this.data.length; i += 1) {
						if (i === indx) {
							this.data[i].status = 3;
						}
					}

					this.showNotification(`Анкета ${row.name} заархивирована!`, 'success');
				});
			}
		},
		publicAnket(row, indx) {
			if (confirm('Вы уверены что хотите опубликовать анкету ?')) {
				let address = '',
					flagIsApprove = false;
				if (row.isPublic) {
					// Публичная анкета
					address = `https://anketanok.minzdrav.gov.ru/${row.id}`;
					flagIsApprove = false;
				} else {
					// Непубличная анкета
					if (row.places.length > 0) {
						for (let i = 0; i < row.places.length; i += 1) {
							row.places[i].url = `${this.setMapRouter[row.places[i].id]}`;
						}
						flagIsApprove = true;
					}
				}

				inlineSaveForUrl(this.$http, {
					obj: {
						id: row.id,
						type: row.type,
						address: address,
						places: row.places,
						status: 2,
						name: row.name,
						setCalculation: row.setCalculation,
						comment: row.comment,
						isPublic: row.isPublic,
						hasComent: row.hasComent,
						isApproved: flagIsApprove,
						questions: []
					},
					url: 'Anketa/UpdateAnketa'
				}).then(() => {
					for (let i = 0; i < this.data.length; i += 1) {
						if (i === indx) {
							this.data[i].status = 2;
							if (!flagIsApprove) this.data[i].address = address;
						}
					}

					this.showNotification(`Анкета ${row.name} опубликованна!`, 'success');
				});
			}
		},
		showNotification(text, type) {
			this.$toast[type](text, {
				position: 'top-right',
				timeout: 3000,
				closeOnClick: true,
				pauseOnFocusLoss: true,
				pauseOnHover: true,
				draggable: true,
				draggablePercent: 0.6,
				showCloseButtonOnHover: false,
				hideProgressBar: true,
				closeButton: 'button',
				icon: true,
				rtl: false
			});
		},
		downloadExcell(params) {
			this.loadToExcell = true;

			this.$http
				.post('Anketa/GetAnketsExcel', params, {
					responseType: 'blob',
					headers: {
						'Access-Control-Expose-Headers': 'Content-Disposition'
					}
				})
				.then((res) => {
					// let fileNameAndType = decodeURI(res.headers['content-disposition'].split('filename*=')[1].split("''")[1]);

					const url = window.URL.createObjectURL(new Blob([res.data]));
					const link = document.createElement('a');
					link.href = url;
					link.setAttribute('download', 'Анкеты.xlsx');
					document.body.appendChild(link);
					link.click();
					document.body.removeChild(link);

					this.loadToExcell = false;
				})
				.catch((error) => {
					if (error) {
						this.loadToExcell = false;
						this.showNotification('Ошибка выгрузки, обратитесь к Администратору!', 'error');
					}
				});
		}
	},
	mounted() {
		this.getData(this.params, false);
	}
};
</script>

<style scoped>
.tr1__sticky {
	background-color: #f9fafb;
	top: 0;
	position: sticky;
	z-index: 7;
}
</style>

<style>
.icon-control {
	cursor: pointer;
}

.icon-control:hover rect {
	fill: #3377ff;
}
</style>
