export const setCreateAnket = (http, params) => {
	return new Promise((resolve, reject) => {
		http.post('Anketa/CreateAnketa', params)
			.then((res) => {
				resolve(res);
			})
			.catch((error) => {
				reject(error);
			});
	});
};
export const setQuestionBlock = (http, params) => {
	return new Promise((resolve, reject) => {
		http.post('Anketa/CreateAnketaBlock', params)
			.then((res) => {
				resolve(res);
			})
			.catch((error) => {
				reject(error);
			});
	});
};
export const setQuestion = (http, params) => {
	return new Promise((resolve, reject) => {
		http.post('Anketa/CreateAnketaQuestion', params)
			.then((res) => {
				resolve(res);
			})
			.catch((error) => {
				reject(error);
			});
	});
};
export const setAnswer = (http, params) => {
	return new Promise((resolve, reject) => {
		http.post('Anketa/CreateAnketaAnswer', params)
			.then((res) => {
				resolve(res);
			})
			.catch((error) => {
				reject(error);
			});
	});
};
export const setDependedQuestion = (http, params) => {
	return new Promise((resolve, reject) => {
		http.post('Anketa/AddDependencyQuestion', params)
			.then((res) => {
				resolve(res);
			})
			.catch((error) => {
				reject(error);
			});
	});
};

export const getAnkets = (http, parametrs) => {
	return new Promise((resolve, reject) => {
		http.post('Anketa/GetAnkets', parametrs)
			.then((res) => {
				resolve(res);
			})
			.catch((error) => {
				reject(error);
			});
	});
};
export const getAnketa = (http, anketaId) => {
	return new Promise((resolve, reject) => {
		http.get(`Anketa/GetAnketaWithBlocks?anketaId=${anketaId}`)
			.then((res) => {
				resolve(res);
			})
			.catch((error) => {
				reject(error);
			});
	});
};
export const getGuid = (http, nameUrl) => {
	return new Promise((resolve, reject) => {
		http.get(nameUrl)
			.then((res) => {
				resolve(res);
			})
			.catch((error) => {
				reject(error);
			});
	});
};

export const deleteBlockForAnket = (http, blockId) => {
	return new Promise((resolve, reject) => {
		http.post(`Anketa/DeleteAnketaBlock?blockId=${blockId}`, {})
			.then((res) => {
				resolve(res);
			})
			.catch((error) => {
				reject(error);
			});
	});
};
export const deleteQuestion = (http, anketaId, questionId) => {
	return new Promise((resolve, reject) => {
		http.post(`Anketa/DeleteAnketaQuestion?anketaId=${anketaId}&questionId=${questionId}`, {})
			.then((res) => {
				resolve(res);
			})
			.catch((error) => {
				reject(error);
			});
	});
};
export const deleteAnswers = (http, anketaId, answerId) => {
	return new Promise((resolve, reject) => {
		http.post(`Anketa/DeleteAnketaAnswer?anketaId=${anketaId}&answerId=${answerId}`, {})
			.then((res) => {
				resolve(res);
			})
			.catch((error) => {
				reject(error);
			});
	});
};
export const deleteDependedQuestion = (http, linkId) => {
	return new Promise((resolve, reject) => {
		http.post(`Anketa/DeleteDependencyQuestion?linkId=${linkId}`, {})
			.then((res) => {
				resolve(res);
			})
			.catch((error) => {
				reject(error);
			});
	});
};
export const deleteAnketa = (http, anketaId) => {
	return new Promise((resolve, reject) => {
		http.post(`Anketa/DeleteAnketa?anketaId=${anketaId}`, {})
			.then((res) => {
				resolve(res);
			})
			.catch((error) => {
				reject(error);
			});
	});
};

export const inlineSaveForUrl = (http, params) => {
	return new Promise((resolve, reject) => {
		http.post(params.url, params.obj)
			.then((res) => {
				resolve(res);
			})
			.catch((error) => {
				reject(error);
			});
	});
};
export const changeAnswerOrder = (http, array) => {
	return new Promise((resolve, reject) => {
		http.post('Anketa/ChangeAnswerOrder', array)
			.then((res) => {
				resolve(res);
			})
			.catch((error) => {
				reject(error);
			});
	});
};
export const copyAnketaFormId = (http, fromAnketaId) => {
	return new Promise((resolve, reject) => {
		http.post(`Anketa/CopyAnketa?fromAnketaId=${fromAnketaId}`, {})
			.then((res) => {
				resolve(res);
			})
			.catch((error) => {
				reject(error);
			});
	});
};
export const updateAnketaStatus = (http, anketaId, status) => {
	return new Promise((resolve, reject) => {
		http.post(`Anketa/UpdateAnketaStatus?anketaId=${anketaId}&status=${status}`, {})
			.then((res) => {
				resolve(res);
			})
			.catch((error) => {
				reject(error);
			});
	});
};
